<template>
<div>
  <b-col v-if="loading">
    <div class="text-center">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </b-col>
  <b-col v-else>
    <b-card>
      <b-card-header>
        <b-card-title>
          {{task.title}}
        </b-card-title>
        <v-md-preview :text="task.description"></v-md-preview>
        Author: {{task.user_author.username}} <br/>
        <div v-if="task.user_author.username===current_user.username || current_user.is_admin">
          Assignees:
          <span
            v-for="assignee in task.users_assigned"
            :key="assignee.username" class="removable-user"
          >{{assignee.username}}
            <b-badge class="removable-user-badge" @click="unassignUser(assignee.username)">
              <b-icon icon="x-circle-fill"></b-icon>
            </b-badge>
          </span>
          <b-button pill size="sm" id="popover-reactive" variant="primary" ref="button">Assign User</b-button>
          <b-popover
              target="popover-reactive"
              triggers="click"
              :show.sync="assign_popover_show"
              placement="auto"
              container="tag-container"
              ref="popover"
          >
            <template #title>
              <div>
                <b-button @click="assign_popover_show=false" class="close" aria-label="Close">
                  <b-icon icon="x-circle" class="mb-2" font-scale="0.8"></b-icon>
                </b-button>
                Users
              </div>
            </template>
            <div v-for="user in active_users.filter((u)=>task.users_assigned.findIndex((u2)=>u2.username===u.username)<0)"
                 :key="user.username"
                 @click="assignUser(user.username)"
                 style="cursor: pointer; padding-bottom: 4px">
              <div>
                <span class="removable-user" style="display: table-cell">{{user.username}} </span>
              </div>
            </div>
          </b-popover>
        </div>
        <div v-else>
          Assignees: <span v-for="assignee in task.users_assigned" :key="assignee.id" class="removable-user">{{assignee.username}}</span> <br/>
        </div>
        <div v-if="deadline !== null">
          Deadline: {{deadline}} ({{deadline_relative}})<span v-if="!task.late" style="color:red">(Past Due!)</span>
        </div>
        <div v-else>
          No deadline specified
        </div>
      </b-card-header>
      <b-card-body>
        <b-button-group>
          <b-button variant="primary" @click="postUpdate()">Post Update</b-button>
          <b-button v-if="task.user_author.username===current_user.username || current_user.is_admin" variant="warning" @click="modifyTask()">Modify Task</b-button>
          <b-button v-if="task.status==='3'" variant="success" @click="markOpen()">Reopen Task</b-button>
          <b-button v-else variant="success" @click="markComplete()">Mark Complete</b-button>
        </b-button-group>
        <h3>Updates</h3>
        <SubmissionList :submission_list="subs" link_prefix="/submission/"></SubmissionList>
      </b-card-body>
    </b-card>

  </b-col>
</div>
</template>

<script>
import http from "@/http-common";
import { DateTime } from "luxon";
import SubmissionList from "@/components/SubmissionList";

export default {
  name: "Task",
  props: [
      "current_user",
      "active_users",
      "loggedIn",
  ],
  components: {
    SubmissionList,
  },
  data: function() {
    return {
      task: {
        title: "",
        description: "",
        deadline: null,
      },
      subs: [],
      loading: true,
      assign_popover_show: false,
    };
  },
  methods: {
    loadTask(id) {
      this.loading = true;
      http.get('/task/'+id)
      .then((resp) => {
        this.task = resp.data.task;
        this.subs = resp.data.subs;
        this.loading = false;
      })
      .catch((err) => {
        http.std_error_handler(this, err);
      })

    },
    loadSubmission(sub) {
      this.$router.push('/submission/'+sub.id);
    },
    postUpdate() {
      this.$router.push(`/update-task/${this.task.id}`);
    },
    markComplete() {
      http.put(`/task/${this.task.id}`,
          {
            status: "3",
          })
      .then(() => {
        this.$router.push("/tasks");
      })
      .catch((err) => {
        http.std_error_handler(this, err);
      });
    },
    markOpen() {
      http.put(`/task/${this.task.id}`,
          {
            status: "2",
          })
          .then(() => {
            this.$router.push("/tasks");
          })
          .catch((err) => {
            http.std_error_handler(this, err);
          });
    },
    unassignUser(username) {
      http.put(`/task/unassign/${this.task.id}?username=${encodeURIComponent(username)}`)
          .then((resp) => {
            this.task = resp.data;
          })
          .catch((err) => {
            http.std_error_handler(this, err);
          })
          .finally(() => {
            this.assign_popover_show = false;
          });
    },
    assignUser(username) {
      http.put(`/task/assign/${this.task.id}?username=${encodeURIComponent(username)}`)
      .then((resp) => {
        this.task = resp.data;
      })
      .catch((err) => {
        http.std_error_handler(this, err);
      })
      .finally(() => {
        this.assign_popover_show = false;
      });
    },
    modifyTask() {
      this.$router.push(`/edit-task/${this.task.id}`);
    }
  },
  computed: {
    deadline_relative() {
      const dt = DateTime.fromISO(this.task.deadline);
      return dt.toRelative();
    },
    deadline() {
      if (this.task.deadline !== null) {
        const dt = DateTime.fromISO(this.task.deadline);
        return dt.toLocaleString(Date.DATE_MED_WITH_WEEKDAY);
      } else {
        return null;
      }
    },
  },
  beforeMount() {
    if (!this.loggedIn) {
      this.$router.push('/login');
    }
  },
  mounted() {
    this.loadTask(this.$route.params.id);
    this.$emit('sync-users');
  }
}
</script>

<style scoped>
.removable-user {
  border-radius: 30rem !important;
  padding: 5px 10px;
  margin: 10px 5px 30px 0;
  font-weight: bold;
  font-size: small;
  background-color:slategray;
  color:white;
  border-color:white;
}

.removable-user-badge {
  cursor: pointer;
  background-color: rgba(0,0,0,0);
}

</style>